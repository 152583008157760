@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    height: 100vh;
    max-width: 100vw;
    /* width: 100vw; */
    display: grid;
    background-size: cover;
    background-position: center;
        
    /* overflow: hidden; */
    /* background-image: url('https://cdn.pixabay.com/photo/2022/07/29/05/22/alps-7351077_960_720.jpg'); */
    /* background-color: #cccccc; */
}

#navbar {
    /* background-image: linear-gradient(rgb(219, 179, 228), rgb(213, 213, 149)); */
    /* box-shadow: 20px 20px 50px rgba(57, 117, 120, 0.84); */
    /* backdrop-filter: blur(20px); */
    /* background: rgba(58, 58, 58, 0.5333); */
    background: rgba(139, 94, 199, 0.533);
    animation: animate 4s ease-in-out infinite;
}

/* #navbar::before{
    content: '';
    position: absolute;
    background-color: transparent;
    animation: animate 4s ease-in-out infinite;

} */

@keyframes animate 
{
    0%
    {
        background: rgba(134, 82, 67, 0.533);
    }
    10%
    {
        background: rgba(146, 145, 75, 0.533);
    }
    20%
    {
        background: rgba(139, 124, 76, 0.533);
    }
    30%
    {
        background: rgba(87, 167, 34, 0.533);
    }
    40%
    {
        background: rgba(65, 143, 124, 0.533);
    }
    50%
    {
        background: rgba(35, 112, 148, 0.533);
    }
    60%
    {
        background: rgba(61, 62, 145, 0.533);
    }
    70%
    {
        background: rgba(85, 38, 143, 0.533);
    }
    80%
    {
        background: rgba(121, 44, 136, 0.533);
    }
    90%
    {
        background: rgba(116, 51, 133, 0.533);
    }
    100%
    {
        background: rgba(134, 82, 67, 0.533);
    }
}

#sidebar {
    /* background-image: linear-gradient(rgb(219, 179, 228), rgb(213, 213, 149)); */
    box-shadow: 20px 20px 50px rgba(38, 35, 35, 0.5);
    backdrop-filter: blur(10px);
    /* background: rgba(58, 58, 58, 0.5333); */
    background: rgba(64, 16, 45, 0.533);
}

.instagram {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    border-radius: 100px;
    cursor: pointer;
}

#instagram{
    /* background-color: #c13584; */
    color: #c13584;
}



  
@media only screen and (max-width: 480px) {}
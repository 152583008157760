.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .album-container {
    position: relative;
    /* height: 300px; */
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #040404;
  }
  /* .album-container:after {
    content: "";
    position: absolute;
    background: #040404d5;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
    right: 0;
  } */
  
  .album-container .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .album-container .image {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .album-container .image p {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    color: white;
    z-index: 100;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .album-container .stripes {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    width: 40%;
    z-index: 100;
    height: 50px;
    display: flex;
    align-items: center;
  }
  
  
  .album-container .stripes span {
    width: 60px;
    height: 5px;
    background-color: white;
    content: '';
    display: block;
    border-radius: 10px;
    margin: 1rem;
    cursor: pointer;
  }
  
  